.btn-secondary {
  color: white !important;
}

.btn-light {
  color: $body-color !important;
}

.btn:focus,
.btn:active,
.btn:hover {
  outline: none;
  box-shadow: none;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.carousel {
  .carousel-indicators {
    li {
      height: 8px;
      width: 8px;
      border-radius: 50%;
      background-color: #cccccc;
      border: 1px solid;
      border-color: var($body-color);
    }
    .active {
      background-color: var(--primary);
      opacity: 1;
    }
  }

  .carousel-item {
    margin-bottom: 40px;
  }
}
