@import '../../scss/variables';

.textField {
  background: #5fc3321a 0% 0% no-repeat padding-box;
  border-radius: 25px;
  opacity: 1;
}

.addressType {
  font-weight: bold;
  font-size: 20px;
}

.title {
  font-weight: bold;
  font-size: 20px;
}
