@import '../../scss/variables';

.orderCode {
  font-size: 20px;
  font-weight: bold;
}
.orderSmall {
  font-size: 12px;
}
.bold {
  font-weight: bold;
}
.box {
  border-bottom: 2px solid theme-color('primary');
}
.box :hover {
  color: lightgray;
}
