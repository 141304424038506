.greenForm {
  input,
  select {
    background: #5fc3321a 0% 0% no-repeat padding-box !important;
    border-radius: 25px !important;
    opacity: 1 !important;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
}
