.container {
  .image {
    border-radius: 10px;
    transform: scale(0.8) rotate(0deg);
    mix-blend-mode: darken;
    transition: 0.3s;
  }

  .title {
    font-size: 19px;
    text-align: center;
    font-weight: bold;
  }
  .infos {
    font-size: 11px;
    text-align: center;
  }

  .text {
    text-align: justify;
    font-size: 14px;
  }
}
