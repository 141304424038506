.stepTitle {
  height: 36px;
  background-color: var(--dark-grey);
  font-weight: bold;
  border-radius: 50px;
  line-height: 36px;

  .stepNumber {
    position: absolute;
    height: 36px;
    width: 36px;
    background-color: var(--primary);
    border-radius: 50px;
    color: white;
    line-height: 30px;
    text-align: center;
    border: 3px solid var(--dark-grey);
  }

  .stepText {
    text-align: center;
    height: 36px;
    width: 100%;
    line-height: 36px;
  }
}
