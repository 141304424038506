@import '../../scss/variables';

.modalHeader {
  border-bottom: 3px solid #5fc332;
  opacity: 1;
}

.textField {
  background: #5fc3321a 0% 0% no-repeat padding-box;
  border-radius: 25px;
  opacity: 1;
}
