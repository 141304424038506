.modal-bt {
  .modal-header {
    border: 0 !important;
    .modal-title {
      border: 0 !important;
    }
  }
  .modal-body {
    border: 0;
  }
  .modal-footer {
    border: 0 !important;
  }
}
