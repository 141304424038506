.bt-swiper-container {
  .swiper-wrapper {
    margin-bottom: 35px;
  }

  .swiper-pagination {
    padding-top: 20px;
    .swiper-pagination-bullet-active {
      background-color: var(--primary);
      height: 8px;
      width: 8px;
    }
  }
}
