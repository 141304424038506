@import '../../scss/variables';

.normalDescription {
  font-size: 14px;
}

.boldDescription {
  font-size: 14px;
  font-weight: bold;
}

.relatedProducts {
  font-size: 22px;
}

.productPrice {
  font-size: 24px;
  font-weight: bold;
}

.sale {
  font-size: 18px;
}

.etichetta {
  top: 10px;
  left: 35px;
  height: 60px;
  width: 60px;
  z-index: 99999;
}

.strikethroughText {
  text-decoration-line: line-through;
}

.addToCartBox {
  background-color: var(--dark-grey);

  .optionsBox {
    .title {
      text-align: left;
    }
    .desccription {
      text-align: left;
    }
  }

  .priceBox {
    text-align: center;
    font-size: 36px;
    font-weight: bold;
  }

  .buttonBox {
    font-weight: bold;
  }
}

.infosAccordion {
  .accordionHeader {
    border-radius: 0%;
    border-width: 0px;
    .accordionHeaderContent {
      padding: 0px;
      border-radius: 0%;
      border-width: 0px;
      font-weight: bold;
      font-size: 14px;
      padding-left: 0px;
      background-color: var(--light);
    }
  }

  .image {
    object-fit: contain;
    width: 240px;
    height: 240px;
    border: 2px solid;
    border-radius: 10px;
  }

  .moreInfo {
    font-size: 14px;
  }
}
.wishlistButton {
  bottom: 30px;
  left: 60px;
  padding: 0;
  background-color: white;
  z-index: 9999;
}
