@import '../../scss/variables';

.dock {
  border-top: 1px solid theme-color('border');
  text-align: center;
  position: fixed;
  left: 0;
  bottom: 0;
  height: 71px;
  width: 100%;
  line-height: 71px;
  background-color: white;
  z-index: 999;
}

.dock-ios {
  padding-bottom: 80px;
}

.phantom {
  display: block;
  height: 71px;
  width: 100%;
}

.button,
.button:hover {
  vertical-align: middle;
}

.badge {
  position: absolute;
}
