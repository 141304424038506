.categoryName {
  width: 100%;
  height: 100%;
  font-size: 20px;
  font-weight: bold;
  border-bottom: 2px solid var(--primary);
}

.box :hover {
  color: lightgray;
}
