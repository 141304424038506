.image {
  height: 100%;
  width: 100%;
}

.input {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid #707070;
  border-radius: 50px;
  opacity: 1;
}

.smallText {
  font-size: 12px;
}

.smallBoldText {
  font-size: 12px;
  font-weight: bold;
}

.checkButton {
  height: 30px;
  margin-left: 10px;
}
