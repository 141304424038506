@import '../../../scss/variables';

.areaName {
  width: 100%;
  height: 100%;
  font-size: 20px;
  font-weight: bold;
  border-bottom: 2px solid theme-color('primary');
}
.box :hover {
  color: lightgray;
}
