@import '../../../scss/variables';

.modalHeader {
  border-bottom: 3px solid var(--primary);
  opacity: 1;
}

.modalFont {
  font-family: 'Montserrat';
}
.modalTitleFont {
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #585858;
  opacity: 1;
}

.modalButton {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 10px;
  opacity: 1;
}

.modalButtonFont {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
