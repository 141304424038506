//@font-face {
//  font-family: 'PT Sans';
//  src: local('PT Sans'),
//    url(../assets/fonts/PT_Sans/PTSans-Regular.ttf) format('opentype');
//}
//
//@font-face {
//  font-family: 'PT Sans';
//  font-weight: 900;
//  src: local('PT Sans'),
//    url(../assets/fonts/PT_Sans/PTSans-Bold.ttf) format('opentype');
//}
//
//@font-face {
//  font-family: 'PT Sans-Italic';
//  src: local('PT Sans-Italic'),
//    url(../assets/fonts/PT_Sans/PTSans-Italic.ttf) format('opentype');
//}
//
//@font-face {
//  font-family: 'PT Sans-Italic';
//  font-weight: 900;
//  src: local('PT Sans-Italic'),
//    url(../assets/fonts/PT_Sans/PTSans-BoldItalic.ttf) format('opentype');
//}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'),
    url(../assets/fonts/MontSerrat/Montserrat-Regular.ttf) format('opentype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 900;
  src: local('Montserrat'),
    url(../assets/fonts/MontSerrat/Montserrat-Bold.ttf) format('opentype');
}

@font-face {
  font-family: 'Montserrat-Italic';
  src: local('Montserrat-Italic'),
    url(../assets/fonts/MontSerrat/Montserrat-Italic.ttf) format('opentype');
}

@font-face {
  font-family: 'Montserrat-Italic';
  font-weight: 900;
  src: local('Montserrat-Italic'),
    url(../assets/fonts/MontSerrat/Montserrat-BoldItalic.ttf) format('opentype');
}
