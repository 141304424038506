//@import url('https://fonts.googleapis.com/css2?family=PT+Sans&display=swap');
@import './scss/_variables';
@import './scss/fonts.scss';
@import '~bootstrap/scss/bootstrap';
@import './scss/Bootstrap.scss';
@import '~swiper/swiper';
@import '~swiper/components/pagination/pagination';
@import '~swiper/components/navigation/navigation';
@import './scss/swiper.scss';

@import '~@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '~@fortawesome/fontawesome-free/scss/solid.scss';
@import '~@fortawesome/fontawesome-free/scss/brands.scss';

@import url('https://bytecno.it/pub/media/css/pwa_style_dock.css');

body {
  font-family: 'Montserrat';
}

.App {
  text-align: center;
}

hr {
  height: 1px;
  border-width: 0;
  background-color: var(--border);
}

.err-alert {
  position: absolute;
  top: 20px;
}
