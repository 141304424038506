@import '../../scss/variables';

.subTitle {
  font-size: 20px;
  font-weight: bold;
}

.user {
  font-size: 24px;
  font-weight: bold;
}

.placeholder {
  font-size: 20px;
  border: 0px;
  background: #5fc3321a 0% 0% no-repeat padding-box;
  border-radius: 25px;
  opacity: 1;
}

.errorMessage {
  color: #ff0000;
}
