$theme-colors: (
  'primary': #5fc332,
  'secondary': #ff9800,
  'danger': teal,
  'border': #707070,
  'light': #fff,
  'light-grey': #efefef,
  'dark-grey': #f7f7f7,
  'text': #585858,
);

$btn-border-radius: 50px;
$btn-border-radius-sm: 50px;
$btn-border-radius-lg: 50px;

$body-color: var(--text);

@function theme-color($key: 'primary') {
  @return map-get($theme-colors, $key);
}
