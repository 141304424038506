.image {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.summaryText {
  font-weight: bold;
  font-size: 18;
}
