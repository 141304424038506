@import '../../scss/_variables.scss';
.nav-item {
  font: normal normal bold 20px/24px Montserrat;
  .nav-link.active {
    background-color: white;
    border-bottom: 3px solid var(--primary);
    color: $body-color;
  }
  .nav-link {
    color: $body-color;
  }
}
