@import '../../scss/variables';

.button {
  border-radius: 0%;
  border-width: 0px;
}

.filtersContainer {
  position: absolute;
  background-color: theme-color('light-grey');
  min-height: 10vh;
  z-index: 9;

  .filterTitleRow {
    border-bottom: 1px solid theme-color('primary');
    font-size: 16px;
  }

  .accordionHeader {
    background-color: theme-color('light-grey');
    border-radius: 0%;
    border-width: 0px;
  }

  .borderBottom {
    border-radius: 0%;
    border-width: 0px;
    border-bottom: 1px solid theme-color('border');
  }
}
