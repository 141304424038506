@import '../../scss/variables';

.image {
  object-fit: contain;
  width: 78px;
  height: 77px;
}

.description {
  font-size: 10px;
}

.price {
  font-size: 18px;
  font-weight: bold;
}
