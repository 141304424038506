@import '../../scss/variables';

.field {
  border-radius: 50px !important;
  font-weight: bold;
}

.button {
  border-radius: 50px;
  font-weight: bold;
}
