@import '../../scss/variables';

.image {
  object-fit: contain;
  width: 147px;
  height: 100%;
}

.orderCode {
  font-family: 'Montserrat';
  font-size: 23px;
}

.normal {
  font-family: 'Montserrat';
  font-size: 16px;
}

.bold {
  font-weight: bold;
}

.withBorder {
  border-bottom: 2px solid theme-color('primary');
}

.price {
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: bold;
  color: var(--primary);
}

.button {
  border-radius: 10px;
  font-size: 16px;
  font-weight: bold;
  height: 50px;
}
