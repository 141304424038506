.modalHeader {
  border-bottom: 0 none;
}

.modalFooter {
  border-top: 0 none;
}

.experience {
  font-size: 15px;
}
