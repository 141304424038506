.noPadding {
  padding-left: 0;
  padding-right: 0;
}

.noMargin {
  margin-left: 0;
  margin-right: 0;
}

.bold {
  font-weight: bold;
}

.strikethroughText {
  text-decoration-line: line-through;
}

.iconSvgGreen {
  filter: invert(66%) sepia(53%) saturate(595%) hue-rotate(56deg)
    brightness(89%) contrast(92%);
}
.iconSvgDarkGrey {
  filter: invert(34%) sepia(0%) saturate(0%) hue-rotate(359deg) brightness(91%)
    contrast(80%);
}

.roundGreentextField {
  background: #5fc3321a 0% 0% no-repeat padding-box;
  border-radius: 25px;
  opacity: 1;
}

.errorMessage {
  color: #ff0000;
}

.textPrimary {
  color: var(--primary);
}
