.cFMfoc {
  display: flex;
  align-items: flex-start;
  flex-flow: nowrap;
  -webkit-box-pack: start;
  justify-content: flex-start;
  width: fit-content;
}

.bjnEhE {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: fit-content;
  cursor: pointer;
  text-decoration: none;
  position: relative;
  color: rgb(0, 0, 0);
  padding: 0px;
}

.product-attribute.classe-energetica {
  padding: 15px 5px;
}

.product-attribute.classe-energetica .label-attribute {
  display: none;
}

@media (min-width: 752px) {
  .czZjZJ {
    height: 40px;
  }

  .ircmJq {
    width: 12px;
  }

  .lgwddR {
    font-size: 10px;
    line-height: 14px;
  }

  .iQTcgx {
    font-size: 50px;
    line-height: 36px;
    height: 40px;
    padding-left: 4px;
    padding-right: 4px;
  }

  .euxnUN {
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 12px solid rgb(0, 0, 0);
  }

  .uxOgQ {
    top: -19px;
    left: -12px;
    border-top: 19px solid transparent;
    border-bottom: 19px solid transparent;
    border-left: 11px solid;
  }

  .uxOgQ {
    top: -19px;
    left: -12px;
    border-top: 19px solid transparent;
    border-bottom: 19px solid transparent;
    border-left: 11px solid;
  }
}

.czZjZJ {
  overflow: hidden;
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  height: 25px;
}

.ircmJq {
  background: rgb(255, 255, 255);
  border-width: 1px 0px 1px 1px;
  border-style: solid;
  border-color: rgb(0, 0, 0);
  border-image: initial;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  width: 8px;
}

.lgwddR {
  color: rgb(0, 0, 0);
  font-family: NotoSansDisplay, NotoSansDisplay-fallback, Arial, sans-serif;
  font-weight: 600;
  font-size: 6px;
  line-height: 8px;
  position: relative;
}

.iPsFaE {
  width: 40%;
  font-size: 0px;
  position: relative;
  top: 0px;
}

.catalog-product-view .iPsFaE {
  top: 3px;
}

.iQTcgx-a {
  background-color: rgb(0, 166, 0);
}

.iQTcgx-b {
  background-color: rgb(77, 189, 56);
}

.iQTcgx-c {
  background-color: rgb(179, 219, 24);
}

.iQTcgx-d {
  background-color: rgb(255, 242, 0);
}

.iQTcgx-e {
  background-color: rgb(250, 178, 11);
}

.iQTcgx-f {
  background-color: rgb(242, 92, 25);
}

.iQTcgx-g {
  background-color: rgb(237, 28, 36);
}

.iQTcgx {
  color: rgb(255, 255, 255);
  display: block;
  font-family: Calibri, sans-serif;
  font-weight: 700;
  width: fit-content;
  border-top: 1px solid rgb(0, 0, 0);
  border-bottom: 1px solid rgb(0, 0, 0);
  -webkit-text-stroke: 1px rgb(0, 0, 0);
  font-size: 26px;
  line-height: 22px;
  height: 25px;
  padding-left: 2px;
  padding-right: 2px;
}

.uxOgQ {
  display: flex;
  position: relative;
  width: 0px;
  height: 0px;
  top: -11.5px;
  left: -8px;
  border-top: 11.5px solid transparent;
  border-bottom: 11.5px solid transparent;
  border-left: 7px solid;
}

.bPkjPs {
  border: 0px;
  clip-path: inset(50%);
  clip: rect(0px, 0px, 0px, 0px);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0px;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.euxnUN {
  display: flex;
  width: 0px;
  height: 0px;
  border-top: 12.5px solid transparent;
  border-bottom: 12.5px solid transparent;
  border-left: 8px solid rgb(0, 0, 0);
}

.bPkjPs {
  border: 0px;
  clip-path: inset(50%);
  clip: rect(0px, 0px, 0px, 0px);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0px;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.dvPJyp {
  margin-top: 4px;
}

.foDsGu {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: fit-content;
  cursor: pointer;
  text-decoration: none;
  position: relative;
  color: #000000;
  padding: 0 0 0 10px;
  margin: 0px 5px 0px 0px;
}

.jKtrlP {
  display: inline;
  text-decoration: underline 1px solid currentcolor;
  text-underline-offset: 3px;
}

.jyiVnD {
  margin: 0px;
  color: currentcolor;
  font-size: 12px;
  line-height: calc(1.33333);
  font-family: NotoSansDisplay, NotoSansDisplay-fallback, Arial, sans-serif;
  font-weight: 400;
  letter-spacing: normal;
}

.uxOgQ-a {
  border-left-color: rgb(0, 166, 0);
}

.uxOgQ-b {
  border-left-color: rgb(77, 189, 56);
}

.uxOgQ-c {
  border-left-color: rgb(179, 219, 24);
}

.uxOgQ-d {
  border-left-color: rgb(255, 242, 0);
}

.uxOgQ-e {
  border-left-color: rgb(250, 178, 11);
}

.uxOgQ-f {
  border-left-color: rgb(242, 92, 25);
}

.uxOgQ-g {
  border-left-color: rgb(237, 28, 36);
}

.catalog-product-view {
  .sc-36679b40-5,
  .sc-36679b40-6,
  .sc-36679b40-7 {
    position: relative;
  }

  .sc-36679b40-5 {
    top: 14px;
  }

  .sc-36679b40-6 {
    top: 4px;
  }

  .sc-36679b40-7 {
    top: -11px;
  }
}
