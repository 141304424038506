@import '../../scss/variables';
.container {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 7px;
  min-height: 338px;
  height: 100%;
  width: 100%;
}

.image {
  object-fit: contain;
  height: 184px;
  width: 100%;
}

.companyName {
  font-size: 14px;
  letter-spacing: 0px;
}

.etichetta {
  top: 15px;
  left: 15px;
  height: 60px;
  width: 60px;
}

.modelName {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0px;
  .textTruncate {
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 5px !important;
  }
}

.description {
  font-size: 14px;
  letter-spacing: 0px;
  height: 45px;

  .textTruncate {
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.price {
  font-size: 27px;
  font-weight: bold;
  letter-spacing: 0px;
  color: theme-color('secondary');
}

.strikethroughText {
  text-decoration-line: line-through;
}
