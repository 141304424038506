@import '../../scss/variables';

.title {
  font-weight: bold;
  font-size: 18px;
  text-align: left;
  border-bottom: 2px solid theme-color('primary');
}

.text {
  font-weight: bold;
  font-size: 16px;
}

.button {
  border: 2px solid;
}
