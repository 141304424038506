@import '../../scss/variables';

.image {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.data {
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0px;
}

.offer {
  font-size: 16px;
  .offerTimer {
    color: #ff0000;
    font-weight: bold;
  }
}

.price {
  color: var(--primary);
  font-weight: bold;
}

.button {
  border-radius: 10px;
  font-size: 16px;
  font-weight: bold;
  height: 50px;
}
