.loader {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: rgba($color: #000000, $alpha: 0.5);
  z-index: 9999999;

  .center {
    width: 100%;
  }
}
