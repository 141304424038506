.header {
  height: 160px;
  background: linear-gradient(180deg, #efefef 90px, white 70px);

  .logo {
    margin-top: 30px;
    margin-bottom: 10px;
  }
}

.search {
  margin-top: 6px;

  .input {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 2px solid #707070;
    border-right: 0px;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    opacity: 1;
    height: 40px;
  }

  .icon {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 2px solid #707070;
    border-left: 0px;
    opacity: 1;
    height: 40px;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
  }
}

.btnAds {
  border: 2px solid;
  font-weight: bold;
}
