@import '../../scss/variables';

.container {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 7px;
  height: 223px;
  width: 100%;
}

.etichetta {
  top: 15px;
  left: 15px;
  height: 60px;
  width: 60px;
}

.image {
  object-fit: contain;
  height: 131px;
  width: 100%;
}

.description {
  font-size: 12px;
  letter-spacing: 0px;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.strikethroughText {
  text-decoration-line: line-through;
}

.price {
  font-size: 12px;
  letter-spacing: 0px;
}
