@import '../../scss/variables';

.price {
  font-weight: bold;
  letter-spacing: 0px;
  color: theme-color('secondary');
}

.price-lg {
  font-size: 36px;
}
.price-md {
  font-size: 27px;
}
.price-sm {
  font-size: 18px;
}
.price-xs {
  font-size: 16px;
}

.price-avised {
  gap: 5px;
}
.price-avised-xs {
  font-size: 10px;
}
.price-avised-sm {
  font-size: 10px;
}
.price-avised-md {
  font-size: 14px;
}
.price-avised-lg {
}
